import { lazy } from 'react';
import { Navigate } from 'react-router';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PrivateRoute from './utils/PrivateRoute';
import AdministratorAccessRolePermissions from 'constants/administratorAccessRolePermissions';

// Dashboard
const DashboardBookingStats = Loadable(lazy(() => import('views/shared/dashboard-booking-stats')));
const DashboardSurveyStats = Loadable(lazy(() => import('views/shared/dashboard-survey-stats')));

// Manage Admins
const ManageAdmins = Loadable(lazy(() => import('views/admin/manage-admins')));
const CreateAdminAccount = Loadable(lazy(() => import('views/admin/manage-admins/create-account')));
const EditAdminAccount = Loadable(lazy(() => import('views/admin/manage-admins/edit-account')));

// Manage Managers
const ManageManagers = Loadable(lazy(() => import('views/admin/manage-managers')));
const CreateManagerAccount = Loadable(lazy(() => import('views/admin/manage-managers/create-account')));
const EditManagerAccount = Loadable(lazy(() => import('views/admin/manage-managers/edit-account')));

// Manage External Users
const ManageExternalUsers = Loadable(lazy(() => import('views/admin/manage-external-users')));
const CreateExternalUserAccount = Loadable(lazy(() => import('views/admin/manage-external-users/create-account')));
const EditExternalUserAccount = Loadable(lazy(() => import('views/admin/manage-external-users/edit-account')));

// Manage Respondents
const ManageRespondents = Loadable(lazy(() => import('views/admin/respondents')));
const CreateRespondent = Loadable(lazy(() => import('views/admin/respondents/create-respondent')));
const EditRespondent = Loadable(lazy(() => import('views/admin/respondents/edit-respondent')));
const UploadRespondentList = Loadable(lazy(() => import('views/admin/respondents/upload-respondent-list')));
const ManageStatuses = Loadable(lazy(() => import('views/admin/respondents/manage-statuses')));
const ViewRespondent = Loadable(lazy(() => import('views/shared/respondent')));

// View Surveys
const ViewSurveys = Loadable(lazy(() => import('views/admin/view-surveys')));
const ViewSurvey = Loadable(lazy(() => import('views/admin/view-survey')));
const ViewRespondentSurvey = Loadable(lazy(() => import('views/shared/view-respondent-survey')));
const AnswerRespondentSurvey = Loadable(lazy(() => import('views/shared/answer-respondent-survey')));

// Manage Survey Types
const ManageSurveyTypes = Loadable(lazy(() => import('views/admin/manage-survey-types')));
const EditSurveyType = Loadable(lazy(() => import('views/admin/manage-survey-types/edit-survey-type')));
const ViewSurveyType = Loadable(lazy(() => import('views/admin/manage-survey-types/view-survey-type')));
const ManageSurveyTypeTextTranslations = Loadable(lazy(() => import('views/admin/manage-survey-type-text-translations')));

// Manage Survey Backgrounds
const ManageSurveyBackgrounds = Loadable(lazy(() => import('views/admin/manage-survey-backgrounds')));

// Manage Access Roles
const ManageAccessRoles = Loadable(lazy(() => import('views/admin/manage-access-roles')));
const CreateAccessRole = Loadable(lazy(() => import('views/admin/manage-access-roles/create-access-role')));
const EditAccessRole = Loadable(lazy(() => import('views/admin/manage-access-roles/edit-access-role')));

// Manage Survey Reports
const ManageSurveyReports = Loadable(lazy(() => import('views/admin/manage-survey-reports')));

const ManageSurveyCommunicationTexts = Loadable(lazy(() => import('views/admin/manage-survey-communication-texts')));

// Manage Recipients
const ManageRecipients = Loadable(lazy(() => import('views/admin/manage-recipients')));
const AddRecipient = Loadable(lazy(() => import('views/admin/manage-recipients/add-recipient')));
const EditRecipient = Loadable(lazy(() => import('views/admin/manage-recipients/edit-recipient')));
const ImportCSV = Loadable(lazy(() => import('views/admin/manage-recipients/import-csv')));
const ManageTags = Loadable(lazy(() => import('views/admin/manage-recipients/manage-tags')));

// Manage App Configuration
const ManageAppConfigurations = Loadable(lazy(() => import('views/admin/manage-app-configurations')));

// Manage API
const ManageApi = Loadable(lazy(() => import('views/admin/manage-api')));

// Manage Integration
const ManageIntegration = Loadable(lazy(() => import('views/admin/manage-integrations')));
const ManageIinsightIntegration = Loadable(lazy(() => import('views/admin/manage-integrations/iinsight')));
const CreateIinsightIntegration = Loadable(lazy(() => import('views/admin/manage-integrations/iinsight/create-integration')));
const GoogleCalendarIntegration = Loadable(lazy(() => import('views/admin/manage-integrations/google-calendar')));
const OutlookCalendarIntegration = Loadable(lazy(() => import('views/admin/manage-integrations/outlook-calendar')));

// Manage Billing
const ManageBilling = Loadable(lazy(() => import('views/admin/manage-billing')));
const CreateBillingAccount = Loadable(lazy(() => import('views/admin/manage-billing/create-billing-account')));
const RechargeBalance = Loadable(lazy(() => import('views/admin/manage-billing/recharge-balance')));
const BillingAccountSettings = Loadable(lazy(() => import('views/admin/manage-billing/billing-account-settings')));

// Manage Services
const ManageServices = Loadable(lazy(() => import('views/admin/manage-services')));
const CreateService = Loadable(lazy(() => import('views/admin/manage-services/create-service')));
const EditService = Loadable(lazy(() => import('views/admin/manage-services/edit-service')));

// Manage Locations
const ManageLocations = Loadable(lazy(() => import('views/admin/manage-locations')));
const CreateLocation = Loadable(lazy(() => import('views/admin/manage-locations/non-affiliate/create-location')));
const EditLocation = Loadable(lazy(() => import('views/admin/manage-locations/non-affiliate/edit-location')));
const CreateAffiliateLocation = Loadable(lazy(() => import('views/admin/manage-locations/affiliate/create-location')));
const EditAffiliateLocation = Loadable(lazy(() => import('views/admin/manage-locations/affiliate/edit-location')));

// Manage Booking Pages
const ManageBookingPages = Loadable(lazy(() => import('views/admin/manage-booking-pages')));
const CreateBookingPage = Loadable(lazy(() => import('views/admin/manage-booking-pages/create-booking-page')));
const EditBookingPage = Loadable(lazy(() => import('views/admin/manage-booking-pages/edit-booking-page')));
const CreateBookingFromBookingPage = Loadable(lazy(() => import('views/admin/manage-booking-pages/create-booking')));

// Manage Bookings
const ManageBookings = Loadable(lazy(() => import('views/admin/manage-bookings')));
// Manage Bookings - Confirmation Text
const BookingConfirmationText = Loadable(lazy(() => import('views/admin/manage-booking-confirmation-text')));

// Manage Follow-up
const ManageConfirmationReminders = Loadable(lazy(() => import('views/admin/manage-confirmation-reminders')));

// Manage Bookings - Calendar
const ManageBookingCalendar = Loadable(lazy(() => import('views/admin/manage-booking-calendar')));

// Manage Bookings - Calendar
const DownloadBookingDocument = Loadable(lazy(() => import('views/admin/download-booking-document')));

// Manage Companies
const ManageCompanies = Loadable(lazy(() => import('views/admin/manage-companies')));
const CreateCompany = Loadable(lazy(() => import('views/admin/manage-companies/create-company')));
const EditCompany = Loadable(lazy(() => import('views/admin/manage-companies/edit-company')));

// ==============================|| ADMIN ROUTING ||============================== //

const AdminRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/dashboard-booking-stats" />
    },
    {
      path: '/dashboard-booking-stats',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.VIEW_BOOKING_DASHBOARD.key}>
          <DashboardBookingStats />
        </PrivateRoute>
      )
    },
    {
      path: '/dashboard-survey-stats',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.VIEW_SURVEY_DASHBOARD.key}>
          <DashboardSurveyStats />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-admins',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_ADMINISTRATORS.key}>
          <ManageAdmins />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-admins/create-account',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_ADMINISTRATORS.key}>
          <CreateAdminAccount />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-admins/edit-account/:adminID',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_ADMINISTRATORS.key}>
          <EditAdminAccount />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-managers',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_MANAGERS.key}>
          <ManageManagers />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-managers/create-account',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_MANAGERS.key}>
          <CreateManagerAccount />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-managers/edit-account/:managerID',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_MANAGERS.key}>
          <EditManagerAccount />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-ext-users',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_MANAGERS.key}>
          <ManageExternalUsers />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-ext-users/create-account',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_MANAGERS.key}>
          <CreateExternalUserAccount />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-ext-users/edit-account/:managerID',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_MANAGERS.key}>
          <EditExternalUserAccount />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RESPONDENTS.key}>
          <ManageRespondents />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/create-respondent',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RESPONDENTS.key}>
          <CreateRespondent />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/edit-respondent/:respondentId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RESPONDENTS.key}>
          <EditRespondent />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/upload-respondent-list',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RESPONDENTS.key}>
          <UploadRespondentList />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/manage-statuses',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RESPONDENTS.key}>
          <ManageStatuses />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/view-respondent/:respondentId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RESPONDENTS.key}>
          <ViewRespondent />
        </PrivateRoute>
      )
    },
    {
      path: '/view-surveys',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key}>
          <ViewSurveys />
        </PrivateRoute>
      )
    },
    {
      path: '/view-survey/:surveyID',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key}>
          <ViewSurvey />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/view-respondent-survey/:surveyID',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RESPONDENTS.key}>
          <ViewRespondentSurvey />
        </PrivateRoute>
      )
    },
    {
      path: '/respondents/answer-respondent-survey/:surveyID',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RESPONDENTS.key}>
          <AnswerRespondentSurvey />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-survey-types',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key}>
          <ManageSurveyTypes />
        </PrivateRoute>
      )
    },
    {
      path: 'manage-survey-types/edit-survey-type/:surveyTypeId/survey-type-version/:surveyTypeVersionId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key}>
          <EditSurveyType />
        </PrivateRoute>
      )
    },
    {
      path: 'manage-survey-types/view-survey-type/:surveyTypeId/survey-type-version/:surveyTypeVersionId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key}>
          <ViewSurveyType />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-survey-type-text-translations',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key}>
          <ManageSurveyTypeTextTranslations />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-access-roles',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_ACCESS_ROLES.key}>
          <ManageAccessRoles />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-access-roles/create-access-role/:accessRoleUserType',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_ACCESS_ROLES.key}>
          <CreateAccessRole />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-access-roles/edit-access-role/:accessRoleId/:accessRoleUserType',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_ACCESS_ROLES.key}>
          <EditAccessRole />
        </PrivateRoute>
      )
    },
    {
      path: '/admin-manage-survey-backgrounds',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SURVEY_BACKGROUNDS.key}>
          <ManageSurveyBackgrounds />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-survey-reports',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.VIEW_REPORTS.key}>
          <ManageSurveyReports />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-survey-communications',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key}>
          <ManageSurveyCommunicationTexts />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-recipients',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RECIPIENTS.key}>
          <ManageRecipients />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-recipients/add-recipient',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RECIPIENTS.key}>
          <AddRecipient />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-recipients/edit-recipient/:recipientId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RECIPIENTS.key}>
          <EditRecipient />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-recipients/import-csv',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RECIPIENTS.key}>
          <ImportCSV />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-recipients/manage-tags',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_RECIPIENTS.key}>
          <ManageTags />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-app-configurations',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_APP_CONFIGURATIONS.key}>
          <ManageAppConfigurations />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-api',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_API.key}>
          <ManageApi />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-integrations',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_APP_CONFIGURATIONS.key}>
          <ManageIntegration />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-integrations/iinsight',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_APP_CONFIGURATIONS.key}>
          <ManageIinsightIntegration />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-integrations/iinsight/create-integration',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_APP_CONFIGURATIONS.key}>
          <CreateIinsightIntegration />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-integrations/google-calendar',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_APP_CONFIGURATIONS.key}>
          <GoogleCalendarIntegration />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-integrations/outlook-calendar',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_APP_CONFIGURATIONS.key}>
          <OutlookCalendarIntegration />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-billing',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BILLING.key}>
          <ManageBilling />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-billing/create-billing-account',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BILLING.key}>
          <CreateBillingAccount />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-billing/recharge-balance',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BILLING.key}>
          <RechargeBalance />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-billing/billing-account-settings',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BILLING.key}>
          <BillingAccountSettings />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-services',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <ManageServices />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-services/create-service',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <CreateService />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-services/edit-service/:serviceId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <EditService />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-locations',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <ManageLocations />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-locations/create-location',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <CreateLocation />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-locations/edit-location/:locationId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <EditLocation />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-locations/create-affiliate-location',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <CreateAffiliateLocation />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-locations/edit-affiliate-location/:locationId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <EditAffiliateLocation />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-pages',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <ManageBookingPages />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-pages/create-booking-page',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <CreateBookingPage />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-pages/edit-booking-page/:bookingPageId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key}>
          <EditBookingPage />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-pages/booking-page/:bookingPageUid/',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BOOKINGS.key}>
          <CreateBookingFromBookingPage />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-pages/booking-page/:bookingPageUid/preview',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BOOKINGS.key}>
          <CreateBookingFromBookingPage previewMode />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-bookings',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BOOKINGS.key}>
          <ManageBookings />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-bookings/:createdBookingId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BOOKINGS.key}>
          <ManageBookings />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-confirmation-text',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_CONFIRMATION_TEXT.key}>
          <BookingConfirmationText />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-confirmation-reminders',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_CONFIRMATION_TEXT.key}>
          <ManageConfirmationReminders />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-booking-calendar',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BOOKINGS.key}>
          <ManageBookingCalendar />
        </PrivateRoute>
      )
    },
    {
      path: '/access-booking-documents/:bookingId/:fileName/download',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_BOOKINGS.key}>
          <DownloadBookingDocument />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-companies',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_COMPANIES.key}>
          <ManageCompanies />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-companies/create-company',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_COMPANIES.key}>
          <CreateCompany />
        </PrivateRoute>
      )
    },
    {
      path: '/manage-companies/edit-company/:companyId',
      element: (
        <PrivateRoute role={AdministratorAccessRolePermissions.MANAGE_COMPANIES.key}>
          <EditCompany />
        </PrivateRoute>
      )
    }
  ]
};

export default AdminRoutes;
